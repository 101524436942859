@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_content {
    padding: 48px 0;

    @include breakpoint(xlarge) {
        padding: 128px 0;
    }

    blockquote {
        padding: 0;

        @include breakpoint(xlarge) {
            width: calc(100% + 60px);
            margin-left: -30px;
            text-align: center;
        }
    }

    @include breakpoint(large down) {
        .heading-h2 {
            margin-bottom: 24px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:98";